<template>
    <v-app style="margin:0 !important;">
        <router-view :key="$route.fullPath"></router-view>
    </v-app>
</template>
<script>
export default {
    name: 'App'
}
</script>
<style>
@font-face {
    font-family: 'LaBelleAutrore';
    src: url('~@/assets/fonts/La Belle Aurore.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Hakgyoansim';
    src: url('~@/assets/fonts/Hakgyoansim Badasseugi OTF L.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Hakgyoansim';
    src: url('~@/assets/fonts/Hakgyoansim Badasseugi OTF B.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

.logo_font {
    font-family: 'LaBelleAutrore', sans-serif !important;
    font-weight: 500;
    font-size:24px;
}

.banner_font {
    font-family: 'Hakgyoansim', sans-serif !important;
    font-weight: 400;
    font-size:60px;
}

.request_font {
    font-family: 'Hakgyoansim', sans-serif !important;
    font-weight: 400;
    font-size:22px;
}
</style>