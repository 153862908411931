export const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "Common" */ './layouts/front/index.vue'),
        children: [
            // 회원 관련
            {
                path: 'auth',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/Login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/Logout.vue')
                    },
                    {
                        path: 'join',
                        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'agree',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/join/agree.vue')
                            },
                            {
                                path: 'form',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/join/form.vue')
                            }
                        ]
                    },
                    {
                        path: 'find',
                        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'id',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/find/id.vue')
                            },
                            {
                                path: 'password',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/find/password.vue')
                            }
                        ]
                    },
                    {
                        path: 'email_certification',
                        component: () => import(/* webpackChunkName "Auth" */ '@/views/auth/EmailCertification.vue')
                    }
                ]
            },

            // 마이페이지
            {
                path: 'mypage',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'dashboard',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/mypage/dashboard.vue')
                    },
                ]
            },

            // 서비스
            {
                path: 'service',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'book',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/service/book.vue')
                    },
                    {
                        path: 'search',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/service/search.vue')
                    },
                    {
                        path: 'text',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/service/text.vue')
                    },
                    {
                        path: 'chat',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/service/chat.vue')
                    },
                    {
                        path: 'divide',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/service/divide.vue')
                    },
                ]
            },

            // 책 후기
            {
                path: 'post',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/post/list.vue')
                    },
                    {
                        path: 'write',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/post/write.vue')
                    },
                    {
                        path: 'read',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/post/read.vue')
                    },
                ]
            },

            // TEST
            {
                path: '/test',
                component: () => import(/* webpackChunkName: "Common" */ '@/tests/index.vue')
            },

            // Home
            {
                path: '/',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/home.vue')
            },
        ]
    },

    // 관리자 페이지
    {
        path: '/admin',
        meta: { sitemap: { ignoreRoute: true } },
        component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/admin/index.vue'),
        children: [
            // Auth
            {
                path: 'auth',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/auth/login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/auth/logout.vue')
                    },
                ]
            },

            // AI 카운트 관리
            {
                path: 'ai',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/ai.vue'),
            },

            // 회원 관리
            {
                path: 'user',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/user.vue'),
            },

            // 관리자 정보수정
            {
                path: 'update',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/update.vue'),
            }
        ]
    }
]