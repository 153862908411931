import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import { routes } from './routes.js'
import store from './store.js'
import vuetify from './plugins/vuetify'
import lodash from 'lodash'
import '@babel/polyfill'

// 이미지 자르기
import VueRx from 'vue-rx'
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd.min'
import 'vuejs-clipper/dist/vuejs-clipper.css'
Vue.use(VueRx)
Vue.use(VuejsClipper)

// AOS 모션
import AOS from 'aos'
import "aos/dist/aos.css"

// Axios
import axios from 'axios'
Vue.prototype.$http = axios

// CustomMethods
import CustomMethods from './methods'
Vue.use(CustomMethods)

Vue.use(Router)

Vue.config.productionTip = false

export const eventBus = new Vue()

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 페이지별 Title
router.beforeEach(async(to, from, next) => {
    let title = ''

    if(to.path.startsWith('/admin')){
        title = '관리자페이지'
    }else if(to.path.startsWith('/home')){
        title = 'HOME'
    }

    document.title = title + (title==''?'':' - ') + '페이지'
    next()
})

new Vue({
    router,
    store,
    vuetify,
    lodash,
    created() {
        AOS.init({
            duration: 1000,
            once: true
        })
    },
    render: h => h(App)
}).$mount('#app')